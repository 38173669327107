import Image from "next/image";
import React, { useRef } from "react";
import toggleBtn from "@/public/toggle-btn.png";
import { Dropdown, Nav } from "react-bootstrap";
import { useRouter } from "next/router";
import Link from "next/link";
import { svgIcons } from "@/styles/svgIcon/svgIcon";
import { useStateContext } from "@/context/state";
import ReactModal from "@/components/common/ReactModal";
import Button from "../common/Button";
import { signOut, useSession } from "next-auth/react";
import useOutsideClick from "@/hooks/useOutsideClick";
import { fetchSessionData, getNameInitials } from "../common/commonFunctions";
import { UserRoles } from "@/components/common/globalConstants";
import { PageLoader } from "@/components/common/Loader";

interface User {
  name: string;
  email: string;
  user_level: string;
}

interface SessionData {
  user: User;
}

/**
 *
 * @returns a Side bar component that helps navigate throughout the project.
 * it contains the user details( image, name & email),
 * the nav links for the pages it navigates to and a logout popup modal.
 *
 */

function Sidebar() {
  const { route } = useRouter();
  const profileRef = useRef(null);
  const { isSidebarOpen, setIsSidebarOpen } = useStateContext();
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const { data: sessionData } = useSession();
  useOutsideClick(showDropdown, profileRef, () => setShowDropdown(false));

  /** method created to close the modal */
  const handleClose = () => {
    setShowModal(false);
  };

  /** method created fo the logout functionality */
  const handleLogout = () => {
    handleClose();
    setLoading(true);
    signOut({ callbackUrl: "/" }).catch((e) => {
      console.log("e", e);
      setLoading(false);
    });
  };

  const color =
    sessionData?.user.user.user_level == UserRoles.User ? "#39FF6C" : "#C873FF";

  return (
    <aside
      id="sidebar-wrapper"
      className={`${showDropdown ? "dropdown-open" : ""}`}
    >
      {loading && <PageLoader />}
      {/* Side bar main nav container */}
      <Nav className="sidebar" activeKey="/">
        {sessionData && sessionData.user && (
          <div className="logo">
            {sessionData.user.user.demo_mode
              ? svgIcons.demoLogo
              : svgIcons.mainLogo}
          </div>
        )}
        <div
          className="toggle-btn"
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          <Image alt="Toggle Button" src={toggleBtn} />
        </div>
        <div className="user-tile" ref={profileRef}>
          {sessionData && sessionData.user && (
            <>
              {" "}
              <span
                className="shortName"
                style={{
                  color: color,
                  border: `solid 1px ${color}`,
                  borderRadius: "100px",
                }}
              >
                {sessionData.user.user.name
                  ? getNameInitials(sessionData.user.user.name)
                  : "MM"}
              </span>
              <div className="user-detail">
                <span className="user-name">{sessionData.user.user.name}</span>
                <span className="user-email">
                  {sessionData.user.user.email}
                </span>
              </div>
            </>
          )}
          <span
            className="caret-icon"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            {" "}
            {svgIcons.logoutTopBottom}
          </span>
          <Dropdown show={showDropdown} placement="top-start">
            <Dropdown.Menu>
              {sessionData && sessionData.user && (
                <Dropdown.Item as={"span"} className="org-title" disabled>
                  {sessionData.user.user.org || "YAMA"}
                  {sessionData.user.user.demo_mode ? " - DEMO" : ""}
                </Dropdown.Item>
              )}
              <Dropdown.Item
                href="https://www.meta-merch.io"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textTransform: "lowercase" }}
              >
                meta-merch.io
              </Dropdown.Item>
              <Dropdown.Item
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  setShowModal(true);
                  setShowDropdown(false);
                }}
              >
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {/* logout popup modal */}

          <ReactModal
            show={showModal}
            onClose={handleClose}
            heading="Logout"
            className="logout-modal-container"
          >
            <>
              <div className="custom-modal-text">
                Are you sure you want to log out?
              </div>
              <div className="modal-button-container">
                <Button className="btnBorderGreen" onClick={handleLogout}>
                  Logout
                </Button>
              </div>
              <div className="modal-cancel-btn" onClick={handleClose}>
                <span>Cancel</span>
              </div>
            </>
          </ReactModal>
        </div>
        <Nav.Item>
          <Link
            href="/dashboard"
            className={`${
              route.startsWith("/dashboard") ? "nav-link active" : "nav-link"
            }`}
          >
            {svgIcons.dashboard} Dashboard
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link
            href="/projects"
            className={`${
              route.startsWith("/projects") ? "nav-link active" : "nav-link"
            }`}
          >
            {svgIcons.projectIcon} Projects{" "}
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link
            href="/team"
            className={`${
              route.startsWith("/team") ? "nav-link active" : "nav-link"
            }`}
          >
            {svgIcons.memberIcon} Your Team
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link
            href="/settings"
            className={`${
              route.startsWith("/settings") ? "nav-link active" : "nav-link"
            }`}
          >
            {svgIcons.settingIcon} Settings
          </Link>
        </Nav.Item>
      </Nav>
    </aside>
  );
}

export default Sidebar;
