import { useStateContext } from "@/context/state";
import { motion } from "framer-motion";


/** This is use to handle the layout props type */

type LayoutProps = {
  children: React.ReactNode;
  className: string;
};

/**
 *
 * @returns a reusable layout component that is rendered on every page
 * and contains the content of every screen.
 */
const Layout = ({ children, className = "" }: LayoutProps) => {
  const { isSidebarOpen, isOpen } = useStateContext();
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 20,
      }}
      id="main"
      className={`${
        isSidebarOpen ? "sidebar-open" : "sidebar-hide"
      } ${className} ${isOpen ? "preview-model-open" : ""}`}
    >

      {children}
    </motion.div>
  );
};
export default Layout;
