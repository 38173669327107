import React, { useEffect, useRef, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useRouter as useNavigation } from "next/navigation";
import { Container } from "react-bootstrap";
import { svgIcons } from "@/styles/svgIcon/svgIcon";
import { useStateContext } from "@/context/state";
import { UserRoles } from "@/components/common/globalConstants";

interface TopbarProps {
  children?: React.ReactNode;
  heading?: string;
  lightMode?: boolean;
  hasHeadingBorder?: boolean;
  showCartBtn?: boolean;
  links?: {
    url?: string;
    title: string;
    active?: boolean;
    back?: boolean;
  }[];
  actions?: {
    icon?: string | JSX.Element;
    onClick?: () => void;
    title?: string;
    type: "button" | "link";
    url?: string;
    className?: string;
  }[];
}

/**
 * @returns Topbar component, which displays a navigation bar at the top of the screen.
 * The component accepts props for heading, links, and buttons to customize its content.
 * The heading prop is an optional string that sets the main title of the Topbar.
 * The links prop is an array of objects, each representing a link in the navigation bar.
 * The buttons prop is an array of objects, each representing a button in the Topbar.
 */

function Topbar({
  links,
  heading,
  actions,
  lightMode = false,
  children = null,
  hasHeadingBorder = false,
  showCartBtn = true
}: TopbarProps) {
  const { userType, setIsCartOpen, cartData } = useStateContext();
  const router = useRouter();
  const navigation = useNavigation();

  return (
    <Container className="topbar" fluid>
      <div className="link-wrapper">
        <div className="top-links">
          {links?.map((link, index) => {

            if (link.url) {
              /* Render a regular link if a URL is provided*/
              return (
                  <Link
                    className={`top-link go-back-link ${
                      link.active ? "active" : ""
                    }`}
                    href={link.url}
                    key={index}
                  >
                    {link.title}
                  </Link>

              );
            } else {
              return (
                  <span
                    className={`top-link  ${
                      link.active ? "active" : "go-back-link"
                    }`}
                    key={index}
                  >
                    {link.title}
                  </span>
              );
            }
          })}
        </div>
        <div className="button-wrapper">
          {userType === UserRoles.Admin && showCartBtn && (
            <Link
              href={"/"}
              className={lightMode ? "cartDarkIcon" : "cartWhiteIcon"}
              onClick={(e) => {
                e.preventDefault();
                setIsCartOpen(true);
              }}
            >
              {cartData?.count == 0 &&
                (lightMode ? svgIcons.cartDarkIcon : svgIcons.cartWhiteIcon)}
              {cartData?.count > 0 &&
                (lightMode ? svgIcons.cartIcon : svgIcons.cartWhiteIconFull)}
              Cart
            </Link>
          )}
        </div>
      </div>
      {heading && (
          <div
            className={`template-heading ${
              hasHeadingBorder ? "border-bottom" : ""
            }`}
          >
            <h3>{heading}</h3>

            {actions && actions.length > 0 && (
              <div className="cta-button-wrapper">
                {actions.map((btn, index) => {
                  const className = btn.className
                    ? btn.className
                    : "cta-button foil-animation";
                  if (btn.type === "link" && btn.url) {
                    /** Render a link button if the type is "link" and a URL is provided */
                    return (
                      <Link className={className} href={btn.url} key={index}>
                        {btn.icon && btn.icon}
                        {btn.title && btn.title}
                      </Link>
                    );
                  } else {
                    /** Render a regular button if the type is not "link" or a URL is not provided */
                    return (
                      <Link
                        key={index}
                        href={"/"}
                        className={className}
                        onClick={(e) => {
                          e.preventDefault();
                          btn.onClick && btn.onClick();
                        }}
                      >
                        {btn.icon && btn.icon}
                        {btn.title && btn.title}
                      </Link>
                    );
                  }
                })}
              </div>
            )}
          </div>
      )}

      {children}
    </Container>
  );
}

export default Topbar;
