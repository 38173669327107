import React from "react";

/**
 * @param onClick - Optional callback function to handle button click event.
 * @param className - CSS class name(s) to apply to the button element.
 * @param disabled - Specifies whether the button is disabled or not.
 * @param children - Optional content to be rendered within the button.
 */

interface IProps {
  onClick?: () => void;
  className: string;
  disabled?: boolean;
  children?: React.ReactNode;
}

/**
 * Custom reusable button component
 */

const Button: React.FC<IProps> = ({
  onClick,
  className,
  disabled,
  children,
}) => {
  return (
    <button className={className} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};

export default Button;
