import moment from "moment";
import { getSession } from "next-auth/react";

/** Function to format the date time */
export const formatDateTime = (dateString: string) => {
  return moment(dateString).format("HH:MM-DD-MM-YYYY");
};

export const dayAgoFormat = (dateString:string) => {
  const duration = moment().diff(moment(dateString).endOf('week'));
  const durationInHours = Math.abs(moment.duration(duration).asHours());

  if (durationInHours < 24) {
    return `${Math.floor(durationInHours)} hours ago`;
  } else {
    return moment(dateString).fromNow();
  }
};

/** Function to get the member name initials to show as the logo */
export const getNameInitials = (name: string) => {
  const words = name.trim().split(" ");
  const numWords = words.length;
  if (numWords === 0) {
    return "";
  }
  if (numWords == 1) {
    return words[0][0].toUpperCase();
  }
  if (numWords >= 3) {
    return words[0][0].toUpperCase() + words[numWords - 1][0].toUpperCase();
  }
  return words[0][0].toUpperCase() + words[1][0].toUpperCase();
};

/** Fetch user's data from session  */
export const fetchSessionData = async () => {
  try {
    const sessionData = await getSession();
    return sessionData?.user ?? null;
  } catch (error) {
    console.error("Error fetching session data:", error);
    return null;
  }
};
